import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import Link from '../components/link'
import ContactForm from '../components/form'
import website from '../data/website.json'
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFax } from 'react-icons/fa'
import PageTemplate from '../components/pageTemplate'
import { graphql } from 'gatsby'

let ContactItem = ({text, type, Icon, href}) => (
  <Col xs={6} lg={3} className="d-flex flex-column align-items-center" style={{wordBreak: 'break-word'}}>
    <div className="h-4-rem w-4-rem bg-primary-faded my-3 absolute-center rounded-circle">
      <Icon size={30}/>
    </div>
    <div className="text-center">
      <h6>{type}</h6>
      <span className="text-secondary">
        {href ? <Link to={href} className="reset">{text}</Link> : text}
      </span>
    </div>
  </Col>
)

export default ({data}) => (
  <PageTemplate title="Contact Us" img={data.fileName}>
    <h5 className="text-center">The team at Raybek Foods would love to hear from you, so please contact us on one of the following numbers or via our online enquiry form.</h5>
    <div className="bg-white text-dark">
      <Container>
        <Row className="py-5">
          <ContactItem text={website.address} type="Address" Icon={FaMapMarkerAlt}/>
          <ContactItem text={website.email} href={`mailto:${website.email}`} type="Email" Icon={FaEnvelope}/>
          <ContactItem text={website.phone} href={`tel:${website.phone}`} type="Phone" Icon={FaPhone}/>
          <ContactItem text={website.fax} type="Fax" Icon={FaFax}/>
        </Row>
        <hr/>
        <Row className="py-5">
          <Col md={6} className="m-auto">
            <h4 className="text-center text-secondary">Message Us</h4>
            <hr className="primary"/>
            <ContactForm/>
          </Col>
        </Row>
      </Container>
    </div>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "banner-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
